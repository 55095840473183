<template>
  <section class="l-content">
    <el-card name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="维保日期">
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="车牌号/维保人/维保单位"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="carNo" slot-scope="scope">
          <el-button type="text" @click="$site.view_car_detail(scope.row.carId)">{{ scope.row.carNo }}</el-button>
        </template>
        <template slot="serviceType" slot-scope="scope">
          <span v-if="scope.row.serviceType == 1" class="text-blue">保养</span>
          <span v-else-if="scope.row.serviceType == 2" class="text-mauve">维修</span>
        </template>
        <template slot="nextRepairMileage" slot-scope="scope">
          <span v-if="scope.row.serviceType == 1">{{ scope.row.nextRepairMileage }}</span>
          <span v-else-if="scope.row.serviceType == 2" class="text-grey">-----</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import repairAddoredit from './repair_addoredit'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'CarRepair',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '车牌号', slot: 'carNo' },
          { label: '责任人', prop: 'reponsibleUserName' },
          { label: '送修时间', prop: 'repairDate', filter: (a, data) => this.$lgh.limitTo(a, 10) },
          { label: '送修人', prop: 'repairMan' },
          { label: '总费用(元)', prop: 'totalCost' },
          { label: '下次保养里程(公里)', slot: 'nextRepairMileage' },
          { label: '服务类别', slot: 'serviceType' },
          { label: '修理厂', prop: 'repairCo' }
        ],
        operation: {
          width: '220',
          header: { type: 'warning', icon: 'el-icon-plus', label: '新增', click: this.add },
          buttons: [{ type: 'primary', icon: 'el-icon-edit', label: '修改', click: this.edit }]
        }
      },
      condition: {
        keyword: '',
        // enterpriseCode: this.userInfo.enterpriseCode,
        dealState: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/car/repair/pagelist', ['enterpriseCode', this.condition])
    },
    async add() {
      await this.$lgh.openDialog({
        ins: repairAddoredit,
        title: '新增维保记录',
        data: {
          departList: this.departList,
          roleList: this.roleList,
          dataInfo: {
            enterpriseCode: this.userInfo.enterpriseCode,
            serviceType: 1,
            createUserId: this.userInfo.userId,
            createUserName: this.userInfo.fullName
          }
        }
      })
      this.$lgh.toastSuccess('新建维保记录成功')

      this.getPageData()
    },
    async edit(item) {
      await this.$lgh.openDialog({
        ins: repairAddoredit,
        title: '修改维保记录',
        data: {
          dataInfo: Object.assign({}, item)
        }
      })
      this.$lgh.toastSuccess('修改维保记录成功')
      this.getPageData()
    }
  }
}
</script>
