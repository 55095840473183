<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh" width="80%">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-row class="twice">
        <el-form-item label="车牌号" prop="carNo">
          <el-input v-model="dataInfo.carNo" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="维保类型" prop="illegalCode">
          <el-radio-group v-model="dataInfo.serviceType">
            <el-radio-button :label="1">保养</el-radio-button>
            <el-radio-button :label="2">维修</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="责任人/驾驶员" prop="reponsibleUserName">
          <el-input v-model="dataInfo.reponsibleUserName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="维修人" prop="repairMan">
          <el-input v-model="dataInfo.repairMan" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="保养/维修日期" prop="repairDate">
          <l-datepicker v-model="dataInfo.repairDate"></l-datepicker>
        </el-form-item>
        <el-form-item label="保养/维修成本" prop="accessoriesCost">
          <el-input v-model="dataInfo.accessoriesCost" autocomplete="off">
            <span slot="suffix">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="工时成本" prop="manHourCost">
          <el-input v-model="dataInfo.manHourCost" autocomplete="off">
            <span slot="suffix">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="总费用" prop="totalCost">
          <el-input v-model="dataInfo.totalCost" autocomplete="off">
            <span slot="suffix">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="当前里程数" prop="repairMileage">
          <el-input v-model="dataInfo.repairMileage" autocomplete="off">
            <span slot="suffix">公里</span>
          </el-input>
        </el-form-item>
        <el-form-item v-if="dataInfo.serviceType == 1" label="下次保养里程" prop="nextRepairMileage">
          <el-input v-model="dataInfo.nextRepairMileage" autocomplete="off">
            <span slot="suffix">公里</span>
          </el-input>
        </el-form-item>
        <el-form-item v-if="dataInfo.serviceType == 1" label="下次保养日期" prop="nextRepairDate">
          <l-datepicker v-model="dataInfo.nextRepairDate"></l-datepicker>
        </el-form-item>
        <el-form-item label="维修厂/单位" prop="repairCo">
          <el-input v-model="dataInfo.repairCo" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注说明" prop="repairDesc">
          <el-input type="textarea" v-model="dataInfo.repairDesc" autocomplete="off"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      activeName: 'base',
      dataRules: {
        roomName: [{ required: true, trigger: 'blur', message: '会议室名称不能为空' }],
        place: [{ required: true, trigger: 'blur', message: '会议室位置不能为空' }]
      }
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    uploaded(res) {
      Object.assign(this.dataInfo, {
        imgUrlList: res
      })
      // this.$set(this.dataInfo)
    },
    save() {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          await this.$lgh.post('api/enterprise/car/repair/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
